import { computed, ref, unref, watchEffect } from 'vue'
import { isPast, isFuture } from 'date-fns'
import { useProfile } from './useProfile'

export interface IQuotaUsage {
  current: number;
  limit: number;
  packages? : any[]
}

const useQuota = () => {
  const { profile } = useProfile()
  const { plan } = unref(profile)

  const used = computed(() => {
    return unref(profile).used
  })

  const isQuotaLoaded = ref(false)

  const quota = ref<IQuotaUsage>({
    current: 0,
    limit: 0,
    packages: []
  })

  const birtday = plan && plan.signatureDate ? new Date(plan.signatureDate.seconds * 1000).getDate() : null

  return {
    isQuotaLoaded,
    quota,
    used,
    birtday
  }
}

export const usePaidQuota = () => {
  const {
    used: computedUsed,
    isQuotaLoaded,
    quota,
    birtday
  } = useQuota()

  const setPackageStats = (packages: any[]) => {
    if (!packages.length) {
      return []
    }
    const packageStats = packages.map((pack: any) => ({
      ...pack,
      isCurrent: false
    }))

    const firstIncompleteIndex = packages.findIndex((pack: any) => !pack.completed)

    if (firstIncompleteIndex >= 0) {
      packageStats[firstIncompleteIndex].isCurrent = true
    } else {
      packageStats[packageStats.length-1].isCurrent = true
    }

    return packageStats
  }
 
  watchEffect(() => {
    const used = computedUsed.value
    if (used !== undefined && used !== null) {
      quota.value = Object.values(used).reduce<IQuotaUsage>((prev: any, currentPeriod: any) => {
        const {
          startDate,
          endDate,
          packages = []
        } = currentPeriod
        if (isPast(startDate.toDate()) && isFuture(endDate.toDate())) {
          let currentPackUsed = 0
          let currentPackContents = 0
          
          const packagesWithStats = setPackageStats(packages)
          const currentPack = packagesWithStats.find((pack: any) => pack.isCurrent)

          if (currentPack !== undefined) {
            currentPackUsed = currentPack.used
            currentPackContents = currentPack.contents
          }

          return {
            current: prev.current + currentPackUsed,
            limit: prev.limit + currentPackContents,
            packages: packagesWithStats
          }
        }

        return {
          current: prev.current,
          limit: prev.limit,
          packages: prev.packages
        }
      },
      {
        current: 0,
        limit: 0,
        packages: []
      })
    }

    isQuotaLoaded.value = true
  })

  return {
    isQuotaLoaded,
    quota,
    birtday
  }
}

export const useFreeQuota = () => {
  const {
    used: computedUsed,
    isQuotaLoaded,
    quota,
  } = useQuota()

  watchEffect(() => {
    const used = computedUsed.value
    if (used !== undefined) {
      quota.value = Object.values(used).reduce<IQuotaUsage>((prev: any, currentPeriod: any) => {
        const {
          startDate,
          endDate,
          usedFreeContent = 0,
          freeContent = 0
        } = currentPeriod

        if (isPast(startDate.toDate()) && isFuture(endDate.toDate())) {
          return {
            current: prev.current + usedFreeContent,
            limit: prev.limit + freeContent
          }
        }

        return {
          current: prev.current,
          limit: prev.limit
        }
      },
      {
        current: 0,
        limit: 0
      })
    }

    isQuotaLoaded.value = true
  })

  return {
    isQuotaLoaded,
    quota
  }
}
