<template>
  <div
    class="flex flex-row justify-start items-center space-x-4"
  >
    <button
      class="select-button"
      v-for="button in buttons"
      :key="button.source"
      :style="button.style"
      :class="{ active: button.source === modelValue }"
      @click="(event) => $emit('update:modelValue', button.source)"
    >
      <i
        class="fab text-white"
        :class="button.class"
      >
      </i>
    </button>
  </div>
</template>

<script lang="ts">
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, unref, watch } from 'vue'

export default defineComponent({
  props: { 
    modelValue: String, 
  },

  events: [
    'update:modelValue'
  ],

  setup (props, { emit }) {
    const {
      socialIcons
    } = useSocialAccounts()

    const buttons = computed(() => {
      return Object.entries(unref(socialIcons)).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    watch(buttons, () => {
      const selected = Object.values(unref(buttons)).find(button => button.available)
      if (selected) {
        emit('update:modelValue', selected.source)
      }
    })

    return {
      buttons
    }
  }
})
</script>

<style scoped>
  @layer components {
    button {
      @apply flex justify-center items-center rounded-full w-6 h-6;
      @apply transform duration-300 ease-in-out;
      @apply focus:outline-none;
    }

    button:not(.active) {
      @apply bg-gray-300 !important;
    }
    button.active {
      @apply shadow-md;
    }

    button:disabled {
      @apply cursor-not-allowed;
      @apply bg-gray-200 !important;
    }
  }
</style>